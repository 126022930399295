import frNemes from "../assets/images/leaders/fr-nemes.png"
import frLiviu from "../assets/images/leaders/fr-liviu.png"
import frSami from "../assets/images/leaders/fr-sami.png"
import frCornel from "../assets/images/leaders/fr-cornel.png"
import frRaul from "../assets/images/leaders/fr-raul.png"
import frClaudiu from "../assets/images/leaders/fr-claudiu.png"


const leaders = [
  {
    name: "Nemes Daniel",
    role: "Pastor",
    imgID: frNemes,
    hash: "LPG[yZjs%#of~poftlj]E1kCMdWB",
  },
  {
    name: "Corșeanschi Liviu",
    role: "Prezbiter",
    imgID: frLiviu,
    hash: "LXGbn;f5.8oz~pofo}j[E1kCMxWB",
  },
  {
    name: "Stan Samuel",
    role: "Prezbiter",
    imgID: frSami,
    hash: "LPFr#3oL?^t7?boft7ayE2kCROWB",
  },
  {
    name: "Zamfir Cornel",
    role: "Diacon",
    imgID: frCornel,
    hash: "LLFYleof?^of.7j[xuayE2j[Mxj@",
  },
  {
    name: "Matea Raul",
    role: "Secretar",
    imgID: frRaul,
    hash: "LVHB-%of%$t7~paytRbHD*juR5ay",
  },
  {
    name: "Sămărtinean Claudiu",
    role: "Prezbiter",
    imgID: frClaudiu,
    hash: "LJEyY+af?^of~os:x]kC9akCMwV@",
  },
];

export default leaders;
