import img1 from "../assets/images/botez-2023/1.jpg"
import img2 from "../assets/images/botez-2023/2.jpg"
import img3 from "../assets/images/botez-2023/3.jpg"
import img4 from "../assets/images/botez-2023/4.jpg"
import img5 from "../assets/images/botez-2023/5.jpg"
import img6 from "../assets/images/botez-2023/6.jpg"
import img7 from "../assets/images/botez-2023/7.jpg"
import img8 from "../assets/images/botez-2023/8.jpg"
import img9 from "../assets/images/botez-2023/9.jpg"
import img10 from "../assets/images/botez-2023/10.jpg"
import img11 from "../assets/images/botez-2023/11.jpg"
import img12 from "../assets/images/botez-2023/12.jpg"
import img13 from "../assets/images/botez-2023/13.jpg"
import img14 from "../assets/images/botez-2023/14.jpg"
import img15 from "../assets/images/botez-2023/15.jpg"
import img16 from "../assets/images/botez-2023/16.jpg"
import img17 from "../assets/images/botez-2023/17.jpg"
import img18 from "../assets/images/botez-2023/18.jpg"
import img19 from "../assets/images/botez-2023/19.jpg"
import img20 from "../assets/images/botez-2023/20.jpg"
import img21 from "../assets/images/botez-2023/21.jpg"
import img22 from "../assets/images/botez-2023/22.jpg"
import img23 from "../assets/images/botez-2023/23.jpg"
import img24 from "../assets/images/botez-2023/24.jpg"
import img25 from "../assets/images/botez-2023/25.jpg"
import img26 from "../assets/images/botez-2023/26.jpg"
import img27 from "../assets/images/botez-2023/27.jpg"
import img28 from "../assets/images/botez-2023/28.jpg"
import img29 from "../assets/images/botez-2023/29.jpg"
import img30 from "../assets/images/botez-2023/20.jpg"
import img31 from "../assets/images/botez-2023/31.jpg"
import img32 from "../assets/images/botez-2023/32.jpg"
import img33 from "../assets/images/botez-2023/33.jpg"
import img34 from "../assets/images/botez-2023/34.jpg"
import img35 from "../assets/images/botez-2023/35.jpg"
import img36 from "../assets/images/botez-2023/36.jpg"
import img37 from "../assets/images/botez-2023/37.jpg"
import img38 from "../assets/images/botez-2023/38.jpg"
import img39 from "../assets/images/botez-2023/39.jpg"
import img40 from "../assets/images/botez-2023/40.jpg"
import img41 from "../assets/images/botez-2023/41.jpg"
import img42 from "../assets/images/botez-2023/42.jpg"
import img43 from "../assets/images/botez-2023/43.jpg"
import img44 from "../assets/images/botez-2023/44.jpg"
import img45 from "../assets/images/botez-2023/45.jpg"
import img46 from "../assets/images/botez-2023/46.jpg"
import img47 from "../assets/images/botez-2023/47.jpg"
import img48 from "../assets/images/botez-2023/48.jpg"
import img49 from "../assets/images/botez-2023/49.jpg"
import img50 from "../assets/images/botez-2023/50.jpg"
import img51 from "../assets/images/botez-2023/51.jpg"
import img52 from "../assets/images/botez-2023/52.jpg"
import img53 from "../assets/images/botez-2023/53.jpg"
import img54 from "../assets/images/botez-2023/54.jpg"
import img55 from "../assets/images/botez-2023/55.jpg"
import img56 from "../assets/images/botez-2023/56.jpg"
import img57 from "../assets/images/botez-2023/57.jpg"
import img58 from "../assets/images/botez-2023/58.jpg"
import img59 from "../assets/images/botez-2023/59.jpg"

const commonTitle = "Botez 2023";

const baptism2023 = [
  {
    url: img1,
    title: commonTitle,
    description: "",
  },
  {
    url: img2,
    title: commonTitle,
    description: "",
  },
  {
    url: img3,
    title: commonTitle,
    description: "",
  },
  {
    url: img4,
    title: commonTitle,
    description: "",
  },
  {
    url: img5,
    title: commonTitle,
    description: "",
  },
  {
    url: img6,
    title: commonTitle,
    description: "",
  },
  {
    url: img7,
    title: commonTitle,
    description: "",
  },
  {
    url: img8,
    title: commonTitle,
    description: "",
  },
  {
    url: img9,
    title: commonTitle,
    description: "",
  },
  {
    url: img10,
    title: commonTitle,
    description: "",
  },
  {
    url: img11,
    title: commonTitle,
    description: "",
  },
  {
    url: img12,
    title: commonTitle,
    description: "",
  },
  {
    url: img13,
    title: commonTitle,
    description: "",
  },
  {
    url: img14,
    title: commonTitle,
    description: "",
  },
  {
    url: img15,
    title: commonTitle,
    description: "",
  },
  {
    url: img16,
    title: commonTitle,
    description: "",
  },
  {
    url: img17,
    title: commonTitle,
    description: "",
  },
  {
    url: img18,
    title: commonTitle,
    description: "",
  },
  {
    url: img19,
    title: commonTitle,
    description: "",
  },
  {
    url: img20,
    title: commonTitle,
    description: "",
  },
  {
    url: img21,
    title: commonTitle,
    description: "",
  },
  {
    url: img22,
    title: commonTitle,
    description: "",
  },
  {
    url: img23,
    title: commonTitle,
    description: "",
  },
  {
    url: img24,
    title: commonTitle,
    description: "",
  },
  {
    url: img25,
    title: commonTitle,
    description: "",
  },
  {
    url: img26,
    title: commonTitle,
    description: "",
  },
  {
    url: img27,
    title: commonTitle,
    description: "",
  },
  {
    url: img28,
    title: commonTitle,
    description: "",
  },
  {
    url: img29,
    title: commonTitle,
    description: "",
  },
  {
    url: img30,
    title: commonTitle,
    description: "",
  },
  {
    url: img31,
    title: commonTitle,
    description: "",
  },
  {
    url: img32,
    title: commonTitle,
    description: "",
  },
  {
    url: img33,
    title: commonTitle,
    description: "",
  },
  {
    url: img34,
    title: commonTitle,
    description: "",
  },
  {
    url: img35,
    title: commonTitle,
    description: "",
  },
  {
    url: img36,
    title: commonTitle,
    description: "",
  },
  {
    url: img37,
    title: commonTitle,
    description: "",
  },
  {
    url: img38,
    title: commonTitle,
    description: "",
  },
  {
    url: img39,
    title: commonTitle,
    description: "",
  },
  {
    url: img40,
    title: commonTitle,
    description: "",
  },
  {
    url: img41,
    title: commonTitle,
    description: "",
  },
  {
    url: img42,
    title: commonTitle,
    description: "",
  },
  {
    url: img43,
    title: commonTitle,
    description: "",
  },
  {
    url: img44,
    title: commonTitle,
    description: "",
  },
  {
    url: img45,
    title: commonTitle,
    description: "",
  },
  {
    url: img46,
    title: commonTitle,
    description: "",
  },
  {
    url: img47,
    title: commonTitle,
    description: "",
  },
  {
    url: img48,
    title: commonTitle,
    description: "",
  },
  {
    url: img49,
    title: commonTitle,
    description: "",
  },
  {
    url: img50,
    title: commonTitle,
    description: "",
  },
  {
    url: img51,
    title: commonTitle,
    description: "",
  },
  {
    url: img52,
    title: commonTitle,
    description: "",
  },
  {
    url: img53,
    title: commonTitle,
    description: "",
  },
  {
    url: img54,
    title: commonTitle,
    description: "",
  },
  {
    url: img55,
    title: commonTitle,
    description: "",
  },
  {
    url: img56,
    title: commonTitle,
    description: "",
  },
  {
    url: img57,
    title: commonTitle,
    description: "",
  },
  {
    url: img58,
    title: commonTitle,
    description: "",
  },
  {
    url: img59,
    title: commonTitle,
    description: "",
  },
];

export default baptism2023;
