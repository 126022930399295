import img1 from "../assets/images/revelion-2024/1.jpg"
import img2 from "../assets/images/revelion-2024/2.jpg"
import img3 from "../assets/images/revelion-2024/3.jpg"
import img4 from "../assets/images/revelion-2024/4.jpg"
import img5 from "../assets/images/revelion-2024/5.jpg"
import img6 from "../assets/images/revelion-2024/6.jpg"
import img7 from "../assets/images/revelion-2024/7.jpg"
import img8 from "../assets/images/revelion-2024/8.jpg"
import img9 from "../assets/images/revelion-2024/9.jpg"
import img10 from "../assets/images/revelion-2024/10.jpg"
import img11 from "../assets/images/revelion-2024/11.jpg"

const commonTitle = "Revelion 2024";

const newYear2024 = [
  {
    url: img1,
    title: commonTitle,
    description: "",
  },
  {
    url: img2,
    title: commonTitle,
    description: "",
  },
  {
    url: img3,
    title: commonTitle,
    description: "",
  },
  {
    url: img4,
    title: commonTitle,
    description: "",
  },
  {
    url: img5,
    title: commonTitle,
    description: "",
  },
  {
    url: img6,
    title: commonTitle,
    description: "",
  },
  {
    url: img7,
    title: commonTitle,
    description: "",
  },
  {
    url: img8,
    title: commonTitle,
    description: "",
  },
  {
    url: img9,
    title: commonTitle,
    description: "",
  },
  {
    url: img10,
    title: commonTitle,
    description: "",
  },
  {
    url: img11,
    title: commonTitle,
    description: "",
  },
]

export default newYear2024
