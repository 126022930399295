const baseURL = "https://drive.google.com/uc?export=view&id=";
const commonTitle = "Grupa Duminicala";

const kids = [
  {
    url: baseURL + "1UA7UDs3l0uEeCVRpKVAmgckP5f62T05R",
    title: commonTitle,
    description: ""
  },
  {
    url: baseURL + "11CghGdSD94pNLFj41OYU0Vc6OKfqPrPu",
    title: commonTitle,
    description: ""
  },
  {
    url: baseURL + "1OqnDps7pJ3LHGizuktMeS7sIELEBFN5a",
    title: commonTitle,
    description: ""
  },
  {
    url: baseURL + "1eirmjP6Zhz0TRQV4Viy3lTmNh8J0AIEl",
    title: commonTitle,
    description: ""
  },
  {
    url: baseURL + "10p3vni1lXOVKvrqm6fqllkuZEUARxaSC",
    title: commonTitle,
    description: ""
  },
  {
    url: baseURL + "1zD1KzeNVEzO6Sframwda_I2GucYH9-aJ",
    title: commonTitle,
    description: ""
  },
  {
    url: baseURL + "1gSjToVCjOTWGTau43GggBrKw9ODXGRIe",
    title: commonTitle,
    description: ""
  },
];

export default kids;
