import img1 from "../assets/images/anniversary2023/1.jpg"
const commonTitle = "Aniversarea a 30 de ani de la  înființarea Bisericii (2023)";

const anniversary2023 = [
  {
    url: img1,
    title: commonTitle,
    description: "",
  },
]

export default anniversary2023
